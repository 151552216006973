<template>
  <div v-if="badges.length > 0">
    <BadgeLabel
      v-for="badge in badges"
      :key="badge.congr"
      :name="badge.name"
      :temple="badge.congr"
      :code="badge.code"
      :no-print="true"
      />
  </div>
</template>

<script>
/* eslint-disable */
import BadgeLabel from '@/components/BadgeLabel';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'AllBadges',
  components: { BadgeLabel },
  data() {
    return {
      badges: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['authenticatedUser']),
  },
  async mounted() {
    this.badges = [
  {
    "name": "ADELFO BARBOSA DE MELO",
    "code": "7KT-BNQ-4RF",
    "congr": "Mercês"
  },
  {
    "name": "Ademir Rodrigues de Souza Castro",
    "code": "GIG-4SG-564",
    "congr": "Outros"
  },
  {
    "name": "Adriana Boeira Alves",
    "code": "NKE-X5P-KJJ",
    "congr": "SEDE"
  },
  {
    "name": "Afonso Roberto Ferreira",
    "code": "1P5-ZS8-IAT",
    "congr": "SEDE"
  },
  {
    "name": "Afonso Roberto Ferreira",
    "code": "M2D-P2E-QNF",
    "congr": "SEDE"
  },
  {
    "name": "Alan Diego Godoy",
    "code": "7D2-GKR-N2Z",
    "congr": "Jardim Dom Bosco"
  },
  {
    "name": "ALDO BASILIO DE FREITAS",
    "code": "J89-DBL-B7M",
    "congr": "Hauer Belem"
  },
  {
    "name": "Ana Carolina Cipriano da Cruz",
    "code": "215-AMW-M3U",
    "congr": "Vila Machado - (Pinheirinho)"
  },
  {
    "name": "Anaides Oliveira Santos",
    "code": "98U-UZF-786",
    "congr": "SEDE"
  },
  {
    "name": "Andrey de Oliveira Garcia",
    "code": "G7R-PCL-6S5",
    "congr": "Vila Maria"
  },
  {
    "name": "Antônio Marcos André",
    "code": "KN2-AXP-EAX",
    "congr": "Vila Santo Antônio"
  },
  {
    "name": "APARECIDO BORRIERO",
    "code": "BDF-NL7-L4Q",
    "congr": "Bairro Novo I"
  },
  {
    "name": "Argemiro Beltrani",
    "code": "Q8C-IA4-9PY",
    "congr": "Autódromo II - (Vl. Ofic)"
  },
  {
    "name": "Arli Patricio",
    "code": "SGI-818-PM3",
    "congr": ""
  },
  {
    "name": "Auzias Paulo Nazario Rodrigues",
    "code": "4ES-RCE-RSG",
    "congr": "Pinheirinho"
  },
  {
    "name": "BENNY ANTONIO CEDENO BERMUDEZ",
    "code": "EK2-5IR-F49",
    "congr": "Jardim da Ordem"
  },
  {
    "name": "BERENICE DE OLIVEIRA CUNHA",
    "code": "DCY-NG4-BC1",
    "congr": "Planta Califórnia"
  },
  {
    "name": "Carlos de Moraes Cunha",
    "code": "3CF-U87-EC8",
    "congr": "Moradias Janaina"
  },
  {
    "name": "Celina Martins Nascimento Schmitz",
    "code": "HPY-I3D-7G8",
    "congr": "Bairro Novo VI"
  },
  {
    "name": "Cesar Batista",
    "code": "4VR-YR5-KBD",
    "congr": "Jardim Castelo Branco"
  },
  {
    "name": "Claudemir viera Teixeira",
    "code": "BG2-3F2-YKK",
    "congr": "Moradias Janaina"
  },
  {
    "name": "Claudia Adriana do Rosário Teles Coelho",
    "code": "INH-HQ7-KZF",
    "congr": "Rio Bonito"
  },
  {
    "name": "CLEVERSON BRANDAO",
    "code": "A8V-33A-14Z",
    "congr": "Vila Santo Antônio"
  },
  {
    "name": "Damião José dos Santos Neto",
    "code": "MRW-D2X-6H1",
    "congr": "Vila Pluma - (Sto. Antonio)"
  },
  {
    "name": "DANIEL BEGUE DE SOUZA",
    "code": "DQ9-7N4-6LE",
    "congr": "Itatiaia"
  },
  {
    "name": "Darte Enilia Silveira Gomes",
    "code": "859-MBJ-DKX",
    "congr": "Outros"
  },
  {
    "name": "Denilson Nunes Consul",
    "code": "PFJ-3P4-3F8",
    "congr": "Jardim São Carlos"
  },
  {
    "name": "DHEMESON DEAN DA COSTA LIMA",
    "code": "QV8-LIL-88J",
    "congr": "Pinheirinho"
  },
  {
    "name": "DIOGENES RENE DE LIMA",
    "code": "GAN-F3Z-ZK7",
    "congr": "SEDE"
  },
  {
    "name": "Dionatan Felipe de França",
    "code": "H71-S21-TGX",
    "congr": "Outros"
  },
  {
    "name": "Ediney Paulo de Souza",
    "code": "W21-73X-94T",
    "congr": "Vila Santo Antônio"
  },
  {
    "name": "Edson Alexandre Pinto",
    "code": "AMZ-6R5-FYV",
    "congr": "Jardim Dom Bosco"
  },
  {
    "name": "Edson José Dos Santos",
    "code": "MYA-Q3J-WHQ",
    "congr": "Tatuquara"
  },
  {
    "name": "ERCI APARECIDA PEDROSO TEODORO",
    "code": "VW9-FGG-S3Y",
    "congr": "Jardim Independência"
  },
  {
    "name": "ESDRAS MOREIRA DE CARVALHO",
    "code": "EV4-QFY-7XQ",
    "congr": "Outros"
  },
  {
    "name": "Ev João Geraldo da silva",
    "code": "K9B-Z11-FV7",
    "congr": "Outros"
  },
  {
    "name": "Ezequiel Raimundo dos Santos",
    "code": "CM4-DHI-BK4",
    "congr": "Jardim Dom Bosco"
  },
  {
    "name": "Fernando Teixeira Faria",
    "code": "X14-35C-96K",
    "congr": "Outros"
  },
  {
    "name": "Gabriel Andrade Chaves Gois",
    "code": "B7P-CBG-3X2",
    "congr": "Tingui"
  },
  {
    "name": "GERSON DOS SANTOS COLEN",
    "code": "75R-V38-XLZ",
    "congr": "Vila Rigoni"
  },
  {
    "name": "GILBERTO VIEIRA COSTA",
    "code": "AVV-R8V-N1H",
    "congr": "Vila Galia"
  },
  {
    "name": "Glacy Boeira Alves",
    "code": "N86-IM8-Q1P",
    "congr": "SEDE"
  },
  {
    "name": "Gustavo de Morais Terres",
    "code": "Z3K-78P-XFX",
    "congr": "Jardim Paraiso"
  },
  {
    "name": "HERCILIO LEODORO",
    "code": "U2A-G9J-2AX",
    "congr": "Vila São João"
  },
  {
    "name": "Ildania dos Santos Bezerra de Souza",
    "code": "RUM-8VH-7IU",
    "congr": "Jardim Dom Bosco"
  },
  {
    "name": "INALDO NASCIMENTO MARINHO",
    "code": "QAA-PHC-79C",
    "congr": "SEDE"
  },
  {
    "name": "Iris Pereira dos Santos Junior",
    "code": "UB5-HFF-XS4",
    "congr": "Autódromo"
  },
  {
    "name": "Jaco Da Silva Coelho",
    "code": "X3X-NTC-99B",
    "congr": "Rio Bonito"
  },
  {
    "name": "JAILSON FIRMINO DA SILVA",
    "code": "BCH-ARL-K3U",
    "congr": "Cajuru"
  },
  {
    "name": "Jair Flausino",
    "code": "ZWN-2M4-C5G",
    "congr": "Planta Evaristo"
  },
  {
    "name": "Jane Aparecida Amaral Roza",
    "code": "NK8-14K-FWP",
    "congr": "Outros"
  },
  {
    "name": "Jeferson Nogueira",
    "code": "7WY-J9K-VWH",
    "congr": "Vera Cruz"
  },
  {
    "name": "João Eli Antunes",
    "code": "ANK-KXQ-NI3",
    "congr": "Vila Verde"
  },
  {
    "name": "João Paulo Mendes Gomes",
    "code": "MKS-44Q-PVR",
    "congr": ""
  },
  {
    "name": "João Pedro Alexandre Schmitz",
    "code": "JSL-LD3-ZAZ",
    "congr": "Umbará"
  },
  {
    "name": "João Vitor da Silva Junior",
    "code": "D6H-XBE-TMD",
    "congr": "Vila Ipiranga"
  },
  {
    "name": "JONATAS LANGE DE ASSUNCAO",
    "code": "J9R-VE7-ZHN",
    "congr": "Alto Bela Vista II (Vera Cruz)"
  },
  {
    "name": "José Alberto de Araújo",
    "code": "318-3P5-3IS",
    "congr": "Outros"
  },
  {
    "name": "José Augusto Munhoz",
    "code": "FZY-K1K-YY4",
    "congr": "Outros"
  },
  {
    "name": "JOSÉ CARLOS DE BRITO",
    "code": "XLK-V1Q-AE4",
    "congr": "Hauer Belem"
  },
  {
    "name": "José Donizeti Benatti",
    "code": "B5B-ELR-NSY",
    "congr": "SEDE"
  },
  {
    "name": "JOSE EDMUNDO RIBEIRO DE FREITAS NETO",
    "code": "VUP-GAE-HC6",
    "congr": "Outros"
  },
  {
    "name": "José Miguel correa",
    "code": "XDF-8EA-V8I",
    "congr": "Lotiguaçu"
  },
  {
    "name": "José Roberto Fornazieri",
    "code": "TW7-THD-N7S",
    "congr": "Jardim da Ordem"
  },
  {
    "name": "José Silva de Oliveira",
    "code": "NS9-ESK-JIV",
    "congr": "Outros"
  },
  {
    "name": "JOSIAS PALESTINO",
    "code": "ZIF-1VQ-WTN",
    "congr": "Ahú de Baixo"
  },
  {
    "name": "KARINE RODRIGUES DOS SANTOS BORGES",
    "code": "S44-EQT-WFI",
    "congr": "Tatuquara"
  },
  {
    "name": "Kauan Rafael Godoy",
    "code": "EXD-I93-PVR",
    "congr": "Jardim Paraiso"
  },
  {
    "name": "Laercio Rodrigues dos Santos",
    "code": "2VY-X5U-9DI",
    "congr": "Bacacheri"
  },
  {
    "name": "Lailda Aparecida da Silva",
    "code": "KKG-3MB-5A8",
    "congr": "Portão"
  },
  {
    "name": "Lázaro Aparecido Noe",
    "code": "V78-7XB-G7Y",
    "congr": "Jardim Castelo Branco"
  },
  {
    "name": "Leandro Elias Belemer",
    "code": "23Y-YWZ-34T",
    "congr": "Outros"
  },
  {
    "name": "Leandro Perretto Lemes",
    "code": "WFZ-CHQ-E45",
    "congr": "Jardim Dom Bosco"
  },
  {
    "name": "Lilhian gomes",
    "code": "G31-2TK-6WP",
    "congr": ""
  },
  {
    "name": "Lindomar dos Santos André Corsino",
    "code": "JPZ-3CU-JLY",
    "congr": "Terra Santa"
  },
  {
    "name": "LUCAS DIEGO DO NASCIMENTO",
    "code": "VP9-129-YJM",
    "congr": "Jardim Castelo Branco"
  },
  {
    "name": "Lucas Gabriel do Nascimento de Lima",
    "code": "LJ8-5BM-Q7E",
    "congr": "Outros"
  },
  {
    "name": "Lucas Henrique Kremer dos Santos",
    "code": "531-7CJ-9DG",
    "congr": "Vila Leonice"
  },
  {
    "name": "MANASSES FURQUIM DOS SANTOS",
    "code": "XC4-E4Y-DXZ",
    "congr": "Fernando de Noronha"
  },
  {
    "name": "Marcelo Belo Dos Santos",
    "code": "463-QDR-2BZ",
    "congr": "Jardim Ludovica"
  },
  {
    "name": "Márcia Tiago Pires correa",
    "code": "855-MNQ-WTN",
    "congr": "Lotiguaçu"
  },
  {
    "name": "Márcio Fernando Pereira de Araújo",
    "code": "JGC-3TS-61V",
    "congr": "Vila Verde"
  },
  {
    "name": "MARIA MADALENA NASCIMENTO KUCCAS",
    "code": "BTX-CC3-243",
    "congr": "Vila Krasinski"
  },
  {
    "name": "Marta Machado hoffmann antunes",
    "code": "LH2-RIS-WLS",
    "congr": "SEDE"
  },
  {
    "name": "Mauro Deraldo de Santana",
    "code": "JLF-EQX-VXQ",
    "congr": "Outros"
  },
  {
    "name": "Melksedeque Leandro dos Santos",
    "code": "TTA-TFD-9FF",
    "congr": "Lotiguaçu"
  },
  {
    "name": "Miguel Iaszczak",
    "code": "IDH-R3U-HV6",
    "congr": "SEDE"
  },
  {
    "name": "Milton Gomes de Lima Rocha",
    "code": "4SF-67B-8L1",
    "congr": "Vila Santo Antônio"
  },
  {
    "name": "Nataniel Adriano Stocco",
    "code": "6Q5-P2G-RIF",
    "congr": "SEDE"
  },
  {
    "name": "NECIONE DIVINA BORGES DE SOUZA",
    "code": "463-1BN-TVW",
    "congr": "Jardim Petrópolis"
  },
  {
    "name": "NILSON PASSOS SOARES",
    "code": "GM5-A43-6FQ",
    "congr": "Ahú de Baixo"
  },
  {
    "name": "Nilton Morais",
    "code": "Q35-VBC-6ZA",
    "congr": "Diagonal"
  },
  {
    "name": "odenir Alves Pinheiro",
    "code": "UI6-EDS-LLU",
    "congr": "Tatuquara"
  },
  {
    "name": "Olicio de Oliveira",
    "code": "7R6-9IP-X9R",
    "congr": "Jardim União"
  },
  {
    "name": "ORANDIR APARECIDO DE SOUZA",
    "code": "LXU-7EU-CRA",
    "congr": "Bairro Novo I"
  },
  {
    "name": "OSVALDINO ROSA DA SILVA",
    "code": "KYV-2KE-LIN",
    "congr": "Prado Velho"
  },
  {
    "name": "Otoniel Barroso",
    "code": "FYA-6JX-51B",
    "congr": "Diagonal"
  },
  {
    "name": "PAULO CESAR DA SILVA",
    "code": "AGV-AXR-N16",
    "congr": "Vila Galia"
  },
  {
    "name": "Paulo Cesar Freitas Zanardine",
    "code": "41W-TNS-XTB",
    "congr": "Jardim Esmeralda"
  },
  {
    "name": "PAULO CEZER KREMER DOS SANTOS",
    "code": "HX9-8S6-NH9",
    "congr": "Vila Leonice"
  },
  {
    "name": "PAULO SERGIO TEODORO",
    "code": "GXC-I7L-EU2",
    "congr": "Jardim Independência"
  },
  {
    "name": "Pedro alves da cruz",
    "code": "NNT-62R-XRI",
    "congr": "Jardim da Ordem"
  },
  {
    "name": "Pr José Aparecido Benedito",
    "code": "SZP-H1X-TNA",
    "congr": "Outros"
  },
  {
    "name": "Raquel Vieira Gonçalves",
    "code": "JZZ-Q27-UMW",
    "congr": "Outros"
  },
  {
    "name": "ROBSON DOUGLAS STEIN",
    "code": "PD6-RRD-ZZI",
    "congr": "Vila Ipiranga"
  },
  {
    "name": "ROGERIO MARCLA DE SOUZA",
    "code": "7CQ-B7C-K6Y",
    "congr": "Jardim Petrópolis"
  },
  {
    "name": "Rozileide Ferreira de Oliveira",
    "code": "RX5-VZJ-TGE",
    "congr": "Jardim Independência"
  },
  {
    "name": "Rubens Castilho de Oliveira",
    "code": "VFK-FUR-PCQ",
    "congr": "SEDE"
  },
  {
    "name": "Ruth Arantes Batista",
    "code": "URV-ZNU-Q5I",
    "congr": "Boa Vista"
  },
  {
    "name": "Samuel Wellington Moreira",
    "code": "NYF-L6A-W32",
    "congr": "Autódromo"
  },
  {
    "name": "Sebastião Bispo de Assunção",
    "code": "5JM-PL3-S2V",
    "congr": "Alto Bela Vista II (Vera Cruz)"
  },
  {
    "name": "Sérgio Murilo Hernandes Cunha",
    "code": "GSM-PQ1-9QF",
    "congr": "Planta Califórnia"
  },
  {
    "name": "SILAS G. FERREIRA DE MIRANDA",
    "code": "SKQ-CVA-7WC",
    "congr": "Hauer Belem"
  },
  {
    "name": "Silas Mateus da Silva Pereira",
    "code": "8YB-CLB-DR8",
    "congr": "SEDE"
  },
  {
    "name": "Silas Pereira de Sousa",
    "code": "7BC-AZ6-S42",
    "congr": "Outros"
  },
  {
    "name": "Sonia Antunes",
    "code": "BGI-578-NVH",
    "congr": "Vila Verde"
  },
  {
    "name": "Suely Silverio Biscaia",
    "code": "DM6-13X-6P9",
    "congr": "Vila Germano"
  },
  {
    "name": "Tatiane Rabelo",
    "code": "EN1-8C1-2RP",
    "congr": "Vila Rigoni"
  },
  {
    "name": "Tiago I. Santos",
    "code": "H27-PFJ-KY1",
    "congr": "Outros"
  },
  {
    "name": "Valdemir Alves de Souza",
    "code": "CFD-5SW-JUL",
    "congr": "Jardim Acrópole"
  },
  {
    "name": "Valquiria Magalhães Pinto",
    "code": "6GZ-PXZ-1Q3",
    "congr": "Jardim Paranaense"
  },
  {
    "name": "WALMIR SAMPAIO DAS CHAGAS",
    "code": "5JR-QGT-N3I",
    "congr": "Vila Galia"
  },
  {
    "name": "zulmiro Félix da Silva",
    "code": "XV5-XSH-XT8",
    "congr": "Portão"
  }
];
  }
}
</script>

<style lang="scss" scoped>

</style>
