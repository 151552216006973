<template lang="">
    <div style="display: flex; flex-direction: row; width: 355px; height: 128px; background-color: #f00; gap: 16px;">
        <img :src="qrUrl" />
        <div style="display: flex; flex-direction: column; justify-content: center;">
            <div style="font-size: 18px; font-weight: bold;">{{valName}}</div>
            <div>{{valTemple}}</div>
        </div>
    </div>
</template>
<script>
import QRCode from 'qrcode'
export default {
    name: "BadgeLabel",
    data() {
        return {
            qrUrl: null,
        }
    },
    props: ['name', 'temple', 'code', 'noPrint'],
    async mounted() {
        this.qrUrl = await QRCode.toDataURL((this.code ?? this.$route.query.code), { errorCorrectionLevel: 'L' });
        this.valName = this.name ?? this.$route.query.name;
        this.valTemple = this.temple ?? this.$route.query.temple;
        
        if (! this.noPrint) {
            setTimeout(() => {
                print();
            }, 100);
            setTimeout(() => {
                window.close();
            }, 200);
        }
    },
}
</script>
<style lang="scss" scoped>
    * {
        background-color: #fff !important;
    }
</style>